import React from 'react'
import { useInView } from 'react-intersection-observer'
import smoothscroll from 'smoothscroll-polyfill'
import diffObjects from 'diff-objects'
import { cn } from '../../lib/helpers'
import Breadcrumb from '../layout/breadcrumb'
import Container from './../container'
import * as styles from './about.module.css'
import { responsiveTitle1 } from './../typography.module.css'

const delayGen = (ms = 60) => {
  let time = 0
  return cb => {
    clearTimeout(time)
    time = setTimeout(cb, ms)
  }
}

const delayFn = delayGen()

const AboutSections = ({ qatifLocImage, qasLocImage, inViewRefs, sectionRefs }) => {
  const { goalsRef, activitiesRef, locationRef, toolsRef } = sectionRefs
  const { goalsInViewRef, activitiesInViewRef, locationInViewRef, toolsInViewRef } = inViewRefs
  // console.log('render sections')
  const goalsSection = (
    <article ref={goalsRef}>
      <h2 className={styles.title}>أهداف الجمعية</h2>
      <div ref={goalsInViewRef}>
        <p>يمكن حصر أهم أهداف جمعية الفلك بالقطيف في التالي:</p>
        <ol>
          <li>
            وضع تصور صحيح للكون بحيث يتعرف من خلاله الناس على عظمة الخالق ودقة صنعه، ومقدار
            الحيز المكاني والزماني الذي يشغله الإنسان في هذا الكون.
          </li>
          <li>نشر الوعي الفلكي بين عامة الناس.</li>
          <li>دراسة وتحقيق التطبيقات الفلكية في الشريعة الإسلامية.</li>
          <li>رصد الظواهر الفلكية وتحديد وقتها ومعايير رؤيتها.</li>
          <li>
            الاستفادة من المهتمين في علم الفلك وخصوصاً أبناء المنطقة وإحياء تراثها الفلكي.
          </li>
          <li>مساعدة الباحثين في عمل البحوث المرتبطة بهذا العلم أو العلوم ذات الصلة.</li>
        </ol>
      </div>
    </article>
  )

  const locationSection = (
    <article ref={locationRef}>
      <h2 className={styles.title}>مقر الجمعية </h2>
      <div ref={locationInViewRef}>
        <p>
          يقع مقر الجمعية في الدور الثالث من نادي الأوجام في غرب مدينة القطيف على مساحة 140 متر
          مربع. ويشتمل المقر على:
        </p>
        <ol>
          <li>
            <strong>غرفة اجتماعات ودروس. </strong>
            وهي مجهزة بالحاسبات الآلية لاستخدامها في عملية تعليم الفلك. وتتسع لاثني عشر متدرب.
          </li>
          <li>
            <strong>غرفة إدارة ومكتبة.</strong>
            وتحتوي على مكتبة الجمعية ويوجد بها جهاز حاسب للتحكم آلياً بمنظار الجمعية.
          </li>
          <li>
            <strong>ساحة للرصد الفلكي. </strong>
            وهي مساحة مكشوفة حيث يثبت في وسطها منظار الجمعية، وتبلغ مساحتها أكثر من 80 متر مربع
            وتتسع لخمسين شخص.
          </li>
        </ol>
        <section>
          <h4 className={styles.title2}>موقع مدينة القطيف</h4>
          <p>
            تقع واحة القطيف في شرق المملكة العربية السعودية وتعتبر من أقدم الحواضر في شرق
            الجزيرة العربية. حيث تحتوي الكثير من آثار الحضارات القديمة. كما أرخها كثير من مؤرخي
            ورحالة العرب. وكانت تسمى سابقاُ الخط. وإليها تنسب الرماح الخطية. ولقد ساهم موقعها
            على ساحل الخليج واشتغال الكثير من أهلها بالزراعة إلى ظهور اهتمام قديم بعلم الفلك.
            كما كان للبعد الثقافي لأهالي القطيف دور في ذلك الاهتمام. والخريطة أدناه توضح موقع
            القطيف الجغرافي.
          </p>
          {qatifLocImage}
        </section>
        <section>
          <h4 className={styles.title2}>موقع الجمعية</h4>
          <p>
            يقع مقر الجمعية حالياً في نادي الأوجام (26:33شمال، 49:57شرق) في غرب مدينة القطيف.
            وتقع بلدة الأوجام على بعد أربعة كيلومترات إلى الغرب من مدينة القطيف على طريق
            الدمام-الجبيل السريع ويقع النادي في غرب بلدة الأوجام على يمين الطريق المؤدي للمقبرة
            حسب المخطط أدناه
          </p>
          {qasLocImage}
        </section>

        <section>
          <h4 className={styles.title2}>مخطط مقر الجمعية</h4>
          <p>ويقع المقر في الدور الثالث من النادي على مساحة 140 متر مربع. ويشتمل المقر على:</p>

          <ol>
            <li>
              غرفة اجتماعات ودروس. وهي مجهزة بالحاسبات الآلية لاستخدامها في عملية تعليم الفلك.
              وتتسع لاثني عشر متدرب.
            </li>
            <li>
              غرفة إدارة ومكتبة. وتحتوي على مكتبة الجمعية ويوجد بها جهاز حاسب للتحكم آلياً
              بمنظار الجمعية.
            </li>
            <li>
              ساحة للرصد الفلكي. وهي مساحة مكشوفة حيث يثبت في وسطها منظار الجمعية، وتبلغ
              مساحتها أكثر من 80 متر مربع وتتسع لخمسين شخص.
            </li>
          </ol>
        </section>
      </div>
    </article>
  )

  const activitiesSection = (
    <section ref={activitiesRef}>
      <h2 className={styles.title}>أهم أنشطة الجمعية</h2>
      <div ref={activitiesInViewRef}>
        <p>يمكن حصر أهم أنشطة الجمعية في:</p>
        <ol>
          <li>رصد ومتابعة الأحداث والظواهر الفلكية وتصويرها. وخصوصاً هلال بداية الشهر.</li>
          <li>إقامة دورات ومحاضرات وندوات تعريفية بالفلك.</li>
          <li> إصدار تقويم قمري سنوي يتضمن أهم أنشطة الجمعية وأهم الأحداث الفلكية.</li>
          <li>إصدار نشرة شهرية فلكية.</li>
          <li> تنظيم زيارات للمدارس والجهات العلمية إلى مقر الجمعية.</li>
          <li>
            المشاركة في المؤتمرات والمعارض الفلكية، وتنظيم الزيارات إلى الهيئات والمراصد
            الفلكية.
          </li>
          <li>تنظيم رحلات ومعسكرات فلكية.</li>
        </ol>
      </div>
    </section>
  )

  const toolsSection = (
    <article ref={toolsRef}>
      <h2 className={styles.title}> أدوات الجمعية</h2>
      <div ref={toolsInViewRef}>
        <section>
          <p>
            تمتلك الجمعية عدة أدوات ووسائل فلكية يمكن الاستفادة منها في الرصد والتعلم الفلكي.
            كذلك تمتلك الجمعية مكتبة فلكية. ويمكن تقسيم الأدوات إلى:
          </p>
          <ul>
            <li>أدوات الرصد الفلكية </li>
            <li>وسائل التعلم والعرض الفلكية</li>
            <li> المكتبة الفلكية</li>
          </ul>
        </section>
        <section>
          <h4 className={styles.title2}>أدوات الرصد الفلكية: </h4>
          <p>حيث تمتلك الجمعية ثلاثة مناظير فلكية:</p>
          <ol>
            <li>
              منظار فلكي آلي بقطر 10 بوصات من نوع (MEAD LX 200 GPS 10"). وهو منظار عاكس يمكن
              ضبطه بواسطة مجموعة نظام الأقمار الاصطناعية العالمي لتحديد المواقع. وهذا النوع من
              المناظير ذو قدرة عالية على توضيح الصورة حيث يمكن بواسطته مشاهدة بعض النجوم في
              الظهيرة. كذلك هو ذو دقة عالية في التوجه نحو الأجرام الفلكية حيث يمكنه التوجه
              آلياً نحو بعض مناطق القمر بواسطة النظام الإلكتروني الملحق به والذي يحتوي على
              ذاكرة تحوي مواقع أكثر من 165 ألف جرم فلكي. وهو مثبت في وضع استوائي على عمود
              فولاذي. ويقع في منتصف منطقة الرصد ويهو مغطى بغطاء قابل للحركة انظر الموقع. كما
              توجد بعض الأجهزة الملحقة بالمنظار للتصوير الفلكي.
            </li>
            <li>
              منظار فلكي يدوي بقطر 4.5 بوصات من نوع MEAD وهو منظار عاكس من النوع النيوتني يمكن
              التحكم به يدوياً.
            </li>
            <li>منظار فلكي يدوي بقطر 3 بوصات. وهو منظار كاسر مكون من عدستين.</li>
          </ol>
        </section>
        <section>
          <h4 className={styles.title2}>وسائل التعلم والعرض الفلكية</h4>
          <p>
            تمتلك الجمعية بعض وسائل العرض والتعلم الفلكية مثل بعض الخرائط السماوية والرسومات
            التوضيحية. كذلك بعض المجسمات الفلكية.
          </p>
        </section>
        <section>
          <h4 className={styles.title2}> المكتبة الفلكية</h4>
          <p>
            تمتلك الجمعية مكتبة فلكية تضم عدة مصادر عربية وأجنبية مقروءة ومسموعة ومرئية. كما
            تضم برامج فلكية للحاسب الآلي. ويمكن إعارتها للأعضاء العاملين
          </p>
        </section>
      </div>
    </article>
  )
  return (
    <React.Fragment>
      <div className={styles.gapSection} />
      {goalsSection}
      {locationSection}
      {activitiesSection}
      {toolsSection}
    </React.Fragment>
  )
}

const intialInViewState = {
  goals: false,
  location: false,
  activities: false,
  tools: false,
  intro: true,
}

const AboutNav = props => {
  const [inViewState, setInViewState] = React.useState(intialInViewState)
  // rest of inviewRef
  const [goalsInViewRef, goalsInView] = useInView({ threshold: 0.55 })
  const [locationInViewRef, locationInView] = useInView({ threshold: 0.23 })
  const [activitiesInViewRef, activitiesInView] = useInView({ threshold: 0.55 })
  const [toolsInViewRef, toolsInView] = useInView({ threshold: 0.55 })
  const [introInViewRef, introInView] = useInView({ threshold: 0.2 })

  // appearently we cannot use inViewRefs for ref.scrollIntoView so we need normal ref for each section
  const goalsRef = React.useRef(null)
  const activitiesRef = React.useRef(null)
  const toolsRef = React.useRef(null)
  const locationRef = React.useRef(null)

  const inViewRefs = {
    goalsInViewRef,
    activitiesInViewRef,
    locationInViewRef,
    toolsInViewRef,
    introInViewRef,
  }
  const sectionRefs = { goalsRef, activitiesRef, toolsRef, locationRef }

  React.useEffect(() => {
    smoothscroll.polyfill()
    props.updateRefs(inViewRefs, sectionRefs)
  }, [])

  //todo make better single state to replace all inviews - done
  React.useLayoutEffect(() => {
    delayFn(() => {
      setInViewState(prevState => {
        const newState = {
          goals: goalsInView,
          location: locationInView,
          activities: activitiesInView,
          tools: toolsInView,
          intro: introInView,
        }
        if (introInView) {
          return intialInViewState
        }
        const { updated, saved } = diffObjects(prevState, newState)
        const updatedNav = Object.entries(updated)
          .filter(([key, val]) => val === true)
          .pop()
        if (updatedNav && updatedNav.length && updatedNav[1]) {
          const updatedNavItem = { [updatedNav[0]]: true }
          return { ...intialInViewState, ...updatedNavItem }
        }
        return prevState
      })
    })
  }, [goalsInView, locationInView, activitiesInView, toolsInView, introInView])

  const scrollIntoView = sectionRef => {
    if (!sectionRef || !sectionRef.current)
      return console.error('scrollIntoView: undefined section ref')
    sectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  return (
    // console.log(inViewState) ||
    <nav className={cn(styles.navWrap, !introInView && styles.stickNav)}>
      <ul>
        <li className={styles[inViewState.goals && 'active']}>
          <a onClick={() => scrollIntoView(goalsRef)}>أهداف الجمعية</a>
        </li>

        <li className={styles[inViewState.location && 'active']}>
          <a onClick={() => scrollIntoView(locationRef)}>مقر الجمعية</a>
        </li>
        <li className={styles[inViewState.activities && 'active']}>
          <a onClick={() => scrollIntoView(activitiesRef)}>أنشطة الجمعية</a>
        </li>
        <li className={styles[inViewState.tools && 'active']}>
          <a onClick={() => scrollIntoView(toolsRef)}> أدوات الجمعية</a>
        </li>
      </ul>
    </nav>
  )
}

const About = props => {
  const [inViewRefs, setInViewRefs] = React.useState({
    goalsInViewRef: null,
    activitiesInViewRef: null,
    locationInViewRef: null,
    toolsInViewRef: null,
    introInViewRef: null,
  })
  const [sectionRefs, setsectionRefs] = React.useState({
    goalsRef: null,
    activitiesRef: null,
    locationRef: null,
    toolsRef: null,
  })
  function updateRefsFn(inVRefs, secRefs) {
    setInViewRefs({ ...inVRefs })
    setsectionRefs({ ...secRefs })
  }
  let navItemStack = [{ name: 'عن الجمعية', slug: 'about' }]
  return (
    <Container>
      <Breadcrumb currentNavItems={navItemStack} />
      <AboutWrapper>
        {inViewRefs.introInViewRef === null ? null : (
          <AboutIntro introInViewRef={inViewRefs.introInViewRef} />
        )}
        <AboutNav updateRefs={updateRefsFn} />
        {inViewRefs.goalsInViewRef === null || sectionRefs.goalsRef === null ? null : (
          <AboutSections
            qatifLocImage={props.qatifLocImage}
            qasLocImage={props.qasLocImage}
            inViewRefs={inViewRefs}
            sectionRefs={sectionRefs}
          />
        )}
      </AboutWrapper>
    </Container>
  )
}

const AboutIntro = props => {
  const { introInViewRef } = props
  return (
    <section ref={introInViewRef}>
      <h1 className={responsiveTitle1}> عن الجمعية</h1>
      <p className={styles.intro}>
        جمعية الفلك بالقطيف (Qatif Astronomy Society - QAS) هي جمعية علمية متخصصة في دراسة علم
        الفلك ونشر المعرفة الفلكية بين أفراد المجتمع بمختلف الوسائل. ونظراً لأهمية هذا العلم
        وارتباطه بأمور الناس الدينية والدنيوية المختلفة تم تأسيس الجمعية في شهر رجب من العام
        1423هـ. بحيث يمكن لجميع أبناء المجتمع الاستفادة منها. ويشرف عليها مجموعة من المهتمين
        بهذا العلم. كما تسعى لضم أو الاستفادة أو التواصل مع كل مهتم في هذا العلم.
      </p>
    </section>
  )
}

const AboutWrapper = props => {
  return <article className={styles.article}>{props.children}</article>
}
export default About
