import React from 'react'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import About from '../components/pages/about'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const AboutPage = props => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allSanityPage(filter: { slug: { current: { regex: "/about/ig" } } }) {
        edges {
          node {
            assets {
              asset {
                gatsbyImageData
                altText
                url
              }
            }
          }
        }
      }
    }
  `)

  const [img1, img2] = data.allSanityPage.edges['0'].node.assets
  const qasLocImage = (
    <GatsbyImage
      src={img2?.asset?.url}
      image={img2?.asset?.gatsbyImageData}
      alt={img2?.asset?.altText || ''}
    />
  )
  const qatifLocImage = (
    <GatsbyImage
      src={img1?.asset?.url}
      image={img1?.asset?.gatsbyImageData}
      alt={img1?.asset?.altText || ''}
    />
  )
  return (
    <Layout>
      <SEO title="عن الجمعية" />
      <About qatifLocImage={qatifLocImage} qasLocImage={qasLocImage} />
    </Layout>
  )
}
export default AboutPage

export const Head = ({ location }) => <SEO pathname={location.pathname}></SEO>