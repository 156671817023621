// extracted by mini-css-extract-plugin
export var active = "about-module--active--f4f0d";
export var article = "about-module--article--8bcb2";
export var base = "about-module--base--542a7";
export var blockQuote = "about-module--blockQuote--ffd81";
export var gapSection = "about-module--gapSection--4240e";
export var inactive = "about-module--inactive--ef20a";
export var intro = "about-module--intro--213bf";
export var large = "about-module--large--00ab9";
export var micro = "about-module--micro--61fca";
export var microCustom = "about-module--microCustom--cd56e";
export var navWrap = "about-module--navWrap--c4ffc";
export var paragraph = "about-module--paragraph--e7c2f";
export var responsiveTitle1 = "about-module--responsiveTitle1--638bd";
export var responsiveTitle2 = "about-module--responsiveTitle2--465ca";
export var responsiveTitle3 = "about-module--responsiveTitle3--b557e";
export var responsiveTitle4 = "about-module--responsiveTitle4--dafd4";
export var small = "about-module--small--6f115";
export var smallCustom = "about-module--smallCustom--01dfd";
export var stickNav = "about-module--stickNav--a5e2a";
export var title = "about-module--title--3f1f6 about-module--responsiveTitle2--465ca";
export var title1 = "about-module--title1--45bc6";
export var title2 = "about-module--title2--12cfc about-module--base--542a7";
export var title3 = "about-module--title3--daaec";